<template>
    <div class="w-full mx-auto lg:p-6 flex text-sm lg:text-base justify-between items-center lg:gap-2">
        <div class="w-full lg:text-sm text-elionPurple-600">
            <div data-aos="fade-top"
                 class="w-full p-4 grid grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-14 bg-tropicalIndigo-50
                 border border-elionPurple-600 rounded-lg lg:rounded-xl">
                <div class="text-center lg:space-y-3 flex flex-col">
                    <microphone-hd-reduction-bruit :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.microphone_hd_reduction_bruit') }}
                    </div>
                </div>
                <div class="text-center lg:space-y-3 flex flex-col">
                    <bluetooth :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.bluetooth') }}
                    </div>
                </div>
                <div class="text-center lg:space-y-3 flex flex-col">
                    <son-spaciale :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.son_spaciale') }}
                    </div>
                </div>
                <div class="text-center lg:space-y-3 flex flex-col">
                    <uva-uvb-protetion :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.uva_uvb_protection') }}
                    </div>
                </div>
                <div class="text-center lg:space-y-3 flex flex-col">
                    <bloque-les-lumieres-bleue :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.bloque_les_lumieres_bleue') }}
                    </div>
                </div>
                <div class="text-center lg:space-y-3 flex flex-col">
                    <verre-resistant :class="classIcon" />
                    <div>
                        {{ $t('iconSpec.verre_resistant') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import SonSpaciale from "@/Components/icons/SonSpaciale.vue";
    import Bluetooth from "@/Components/icons/Bluetooth.vue";
    import MicrophoneHdReductionBruit from "@/Components/icons/MicrophoneHdReductionBruit.vue";
    import UvaUvbProtetion from "@/Components/icons/UvaUvbProtetion.vue";
    import BloqueLesLumieresBleue from "@/Components/icons/BloqueLesLumieresBleue.vue";
    import VerreResistant from "@/Components/icons/VerreResistant.vue";
    const classIcon = 'mx-auto text-elionPurple-600 border-elionPurple-600 w-16 h-16 lg:w-22 lg:h-22 border border rounded-lg lg:rounded-full shadow-md lg:shadow-lg';
</script>
